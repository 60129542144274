<template>
  <div>
    <TalkRoomLink
        v-for="talk_room in talk_rooms"
        :talk_room="talk_room"
        :mode="mode"
        :key="`talk_rooms_${talk_room.id}`"></TalkRoomLink>
  </div>
</template>

<script>
import TalkRoomLink from '@/components/TalkRoomLink';
export default {
  name: 'TalkRoomList',
  components: { TalkRoomLink },
  props: {
    talk_rooms: Array,
    mode: {
      validator: function (value) {
        return ['holder', 'selector'].indexOf(value) !== -1;
      },
    },
    artistId: Number,
  },
}
</script>