<template>

  <div class="acd artist_items">
    <input id="artist_items" type="checkbox" class="acd-hidden" @change="expand">
    <label for="artist_items" class="acd-open">販売商品</label>
    <label class="acd-close items">
      <div v-for="(item, index) in items" :key="item.id">
        <ArtistItem v-bind="item"></ArtistItem>
        <hr class="item_hr_w100 mtb_20" v-if="index+1 < items.length">
      </div>
    </label>
  </div>

</template>

<script>
import ArtistItem from '@/components/ArtistItem';
export default {
  components: { ArtistItem },
  data () {
    return {
      items: []
    }
  },
  methods: {
    expand() {
      const elm = document.getElementById('artist_items');
      if (elm.checked) {
        this.loadItems();
      }
    },
    async loadItems () {
      const response = await this.$http.get('items');
      this.items = response.data;
    }
  }
}
</script>