<template>
  <article id="wrap" >
    <AppHeader theme="gray">わたしのこと</AppHeader>
    <div class="undelivered" v-if="isArtist && loginUser.order_unshipped_count > 0">
      <RouterLink to="orders">未配送：{{ loginUser.order_unshipped_count }}件</RouterLink>
    </div>
    <section class="contents">

      <div class="contentInner mb_100">
        <MyUpload :image="loginUser.image" @uploadImage="upload"></MyUpload>

        <h2>{{ loginUser.nick}}</h2>

        <ul class="bt_masters" v-if="openSell && isArtist">
          <li class="bt_master"><RouterLink :to="{name: 'item-create'}">
            <img src="@/assets/img/mypage/request.svg" alt="販売申請アイコン画像"><br>販売申請
          </RouterLink></li>
          <li class="bt_master"><RouterLink :to="{name: 'orders'}">
            <img src="@/assets/img/mypage/order.svg" alt="受注アイコン画像"><br>受注
          </RouterLink></li>
          <!-- <li class="bt_master" @click="openChat"> -->
          <li class="bt_master"><RouterLink :to="{name: 'ai-chat-room' }">
            <img src="@/assets/img/mypage/ai_maneger.svg" alt="AIマネージャーアイコン"><br><small>AIマネージャ</small>
          </RouterLink> </li>
        </ul>

        <div class="mb_10"><span class="main-t">MASTER</span><span class="sub-t">マスターリスト</span></div>
        <TalkRoomList :talk_rooms="loginUser.talk_rooms" mode="holder"></TalkRoomList>
        <div v-if="!loginUser.talk_rooms.length">登録されているマスターはいません</div>
        <div class="bt_form mt_20 mb_30"><router-link :to="{name: 'artist-add'}">マスターを追加</router-link></div>

        <ArtistParticipants v-if="openSell && isArtist"></ArtistParticipants>
        <ArtistItems v-if="openSell && isArtist"></ArtistItems>
        <MyProfile></MyProfile>
        <MyOrders v-if="openSell"></MyOrders>
        <MyDigitalContents @modalOpenPreview="modalOpenPreview" v-if="openSell"></MyDigitalContents>

        <!-- アルバム -->
        <div class="acd">
          <input id="check4" type="checkbox" class="acd-hidden">
          <label for="check4" class="acd-open">アルバム</label>
          <label for="check4" class="acd-close">
            <MyAlbum class="list_album" :album="album" @modalOpenPreview="modalOpenPreview"></MyAlbum>
          </label>
        </div>

        <!-- チャットgptモーダル -->
        <ChatGptModal v-if="showModal" @close="showModal = false">
          <h3 slot="header">パスワード</h3>
          <input v-model="userPassword" @keydown.enter="sendPassword" placeholder="パスワードを入力してください。" />
        </ChatGptModal>

      </div><!--.contentInner-->

    </section>

    <AppFooter class="foot_yellow" fixed></AppFooter>

    <modal
        class="modal-wrapper" name="modal-preview" id="modal-preview" :adaptive="true" :scrollable="true"
        width="100%" height="100%" :styles="{backgroundColor:'transparent'}">
      <a @click="$modal.hide('modal-preview')" class="modal-overlay2"></a>
      <div class="img_preview"><img v-if="preview_image" :src="preview_image" alt=""></div>
      <a @click="$modal.hide('modal-preview')" class="modal-close">
        <img src="@/assets/img/talk/icon_close.png" alt="モーダル閉じるボタン">
      </a>
    </modal>

  </article>
</template>

<script>
import AppHeader from '@/components/AppHeader';
import AppFooter from '@/components/AppFooter';
import MyUpload from '@/components/MyUpload';
import TalkRoomList from '@/components/TalkRoomList';
import MyProfile from '@/components/MyProfile';
import MyAlbum from '@/components/MyAlbum';
import ArtistParticipants from '@/components/ArtistParticipants';
import ArtistItems from '@/components/ArtistItems';
import MyOrders from '@/components/MyOrders';
import MyDigitalContents from '@/components/MyDigitalContents';
import axios from 'axios';
import ChatGptModal from '@/components/ChatGptModal'
export default {
  name: 'MyPage',
  components: {AppHeader, AppFooter, MyUpload, TalkRoomList, MyProfile, MyAlbum, ArtistParticipants, ArtistItems, MyOrders, MyDigitalContents, ChatGptModal},
  computed: {
    loginUser () {
      console.log(this.$store.state.loginUser)
      return this.$store.state.loginUser;
    },
    isArtist () {
      return !!this.loginUser.artist_id;
    }
  },
  data () {
    return {
      album: [],
      orders: null,
      digital_items: null,
      preview_image: null,
      openSell: true,
      showModal: false,
      userPassword: '',
      messages: []
    }
  },
  watch: {
    userPassword(newVal) {
      if (newVal === 'ai-password') {
          this.$router.push({ name: 'ai-chat-room' }); // 'ai-chat-room' ルートに遷移
      }
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    async initialize () {
      // 自分の情報をリロード
      const response = await this.$http.get('me');
      this.$store.commit('setLoginUser', response.data);

      // アルバム情報
      const albumResponse = await axios.get(`https://${process.env.VUE_APP_CHAT_SERVER}/user/${this.loginUser.id}/album`);
      this.album = albumResponse.data;
    },
    async upload($event) {
      const response = await this.$http.put('me', {image_b64: $event});
      const date = new Date(); // 同名ファイルでもキャッシュ無効にするためダミー文字列をつける
      this.$store.commit('setLoginUser', Object.assign(
          response.data, {
            image: response.data.image + '?time=' + Math.floor( date.getTime() / 1000 )}
          )
      );
    },
    modalOpenPreview (attachment) {
      this.preview_image = attachment;
      this.$modal.show('modal-preview');
    },
    async openChat() {
      this.showModal = true;
    },
    closeChat() {
      this.showModal = false;
      this.userPassword = '';
      this.messages = [];
    },
    sendPassword() {
        if (this.userPassword == 'ai-password') {
            this.$router.push({ name: 'ai-chat-room' }); // 'ai-chat-room' ルートに遷移
        } else {
            // パスワードが一致しない場合の処理（任意）
        }
    },
    resetChat() {
      this.userPassword = '';
      this.messages = [];
    },
  }
}
</script>

<style lang="scss" scoped>
.modal-content {
  padding: 20px;
  max-height: 80vh;
  overflow-y: auto;
}

#modal-preview {
  .modal-overlay2 {
    /* z-index: 10; */
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.9);
  }

  .img_preview {
    box-sizing: border-box;
    /* display: inline-block; */
    z-index: 20;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .modal-close {
    z-index: 20;
    position: absolute;
    right: 20px;
    bottom: 20px;

    img {
      width: 36px;
      height: 35px;
    }
  }

  .vm--overlay {
    background: rgba(0, 0, 0, 0.9);
  }
}

.master-t {
    font-size: 30px;
  }
  .main-t {
    font-size: 17px;
  }
  .sub-t {
    margin-left: 10px!important;  /* 余白の量を20pxに設定。この値はお好みに応じて変更可能 */
    font-size: 10px;
}
</style>
