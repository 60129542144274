<template>
  <div class="acd">
    <input id="check1" type="checkbox" class="acd-hidden">
    <label for="check1" class="acd-open first-acd">会員情報</label>
    <label class="acd-close">
      <div class="profile_item">
        <div class="item_label">ニックネーム</div>
        <div>{{ loginUser.nick }}</div>
      </div>

      <div class="profile_item">
        <div class="item_label">お名前</div>
        <div>{{ loginUser.last_name }} {{ loginUser.first_name }}</div>
      </div>

      <div class="profile_item">
        <div class="item_label">フリガナ</div>
        <div>{{ loginUser.last_kana }} {{ loginUser.first_kana }}</div>
      </div>

      <div class="profile_item">
        <div class="item_label">生年月日</div>
        <div>{{ loginUser.birthday }}</div>
      </div>

      <div class="profile_item">
        <div class="item_label">メールアドレス</div>
        <div>{{ loginUser.email }}</div>
      </div>

      <div class="profile_item">
        <div class="item_label">パスワード</div>
        <div>●●●●●●●</div>
      </div>

      <div class="profile_item">
        <div class="item_label">ひと言</div>
        <div>{{ loginUser.comment }}</div>
      </div>

      <div class="profile_item" v-if="loginUser.twitter || loginUser.facebook || loginUser.instagram">
        <div class="item_label">SNS</div>
        <SnsLink :user="loginUser"></SnsLink>
      </div>

      <div class="profile_item" v-if="loginUser.talk_rooms && loginUser.talk_rooms.length">
        <div class="item_label">クレジットカード</div>
        <PaymentCardChange title="クレジットカード変更"
                     :onTokenCreatedSubscription="onTokenCreatedSubscription"
                     :onTokenFailedSubscription="onTokenFailedSubscription"
                     :isTextLink="true">
        </PaymentCardChange>
      </div>
      <!-- <div class="profile_item">
        <label>Xアカウント</label>
        <div class="flex" style="text-align: center;">
          <div class="profile_sns_link"> 
            https://X.com/ 
          </div>
          <div class="">
            <input class="w300" placeholder="username" name="twitter_account" type="text" value="">
          </div>
        </div>
      </div>
      <div class="profile_item">
        <label>Instagramアカウント</label>
        <div class="flex">
          <div class="profile_sns_link"> 
            https://www.instagram.com/ 
          </div>
          <div class="">
            <input class="w300" placeholder="taro" name="instagram_account" type="text" value="">
          </div>
        </div>
      </div>
      <div class="profile_item">
        <label>TikTokアカウント</label>
        <div class="flex">
          <div class="profile_sns_link"> 
            https://www.tiktok.com/
          </div>
          <div class="">
            <input class="w300" placeholder="your.username" name="tiktok_account" type="text" value="">
          </div>
        </div>
      </div>
      <div class="profile_item">
        <label>Youtubeアカウント</label>
        <div class="flex">
          <div class="profile_sns_link"> 
            https://www.youtube.com/
          </div>
          <div class="">
            <input class="w300" placeholder="channelname" name="youtube_account" type="text" value="">
          </div>
        </div>
      </div> -->
      <div class="bt_form mb_20"><router-link :to="{name: 'user-edit'}">会員情報変更</router-link></div>
    </label>
  </div>
</template>

<script>
import SnsLink from '@/components/SnsLink';
import PaymentCardChange from '@/components/PaymentCardChange';
export default {
  name: 'MyProfile',
  components: {SnsLink, PaymentCardChange},
  computed: {
    loginUser () {
      return this.$store.state.loginUser;
    }
  },
  methods: {
    async onTokenCreatedSubscription(token=null) {
      return new Promise( (resolve, reject) => {
        if (!token) token = document.getElementsByName('payjp-token')[0].value;
        const options = {token};
        const url = `me/credit`;
        this.$http.put(url, options).then((response) => {
          if (response.data.status === 'OK') {
            this.$toasted.success('カード情報を更新しました', {
              position: 'bottom-center',
              duration: 2000,
            });
            resolve();
          } else {
            this.$toasted.error('カード情報更新に失敗しました', {
              position: 'top-center',
              duration: 2000,
            });
            reject();
          }
        });
      });
    },
    onTokenFailedSubscription() {
      console.log('fails');
      // 処理
      this.$toasted.error('カード情報更新に失敗しました', {
        position: 'top-center',
        duration: 2000,
      });
    },
  },
}
</script>
<style>
.first-acd {
  border-top: solid;
}
</style>