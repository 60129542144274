<template>
  <div class="item">
    <ul class="col2 mb_10">
      <li class="col2L buy_date"><img src="@/assets/img/mypage/icon_calendar.png" alt="カレンダーアイコン">
        発売日：{{ authorized_at | formatDate }}
      </li>
      <li class="col2R">
        <span class="mark_sale_on" v-if="isSell">販売中</span>
        <span class="mark_sale_off" v-if="!isSell && stock > 0">売り切れ</span>
        <span class="mark_sale_off" v-if="!isSell && stock === 0">販売終了</span>
      </li>
    </ul>
    <ul class="col2">
      <li class="col2L">
        <div class="item_photo">
          <img src="@/assets/img/mypage/item_photo.jpg" alt="商品画像" v-if="item_photos.length === 0">
          <img :src="item_photos[0].image | imageUrl" alt="商品画像" v-if="item_photos.length > 0">
        </div>
      </li>
      <li class="col2R">
        <div class="item_title">{{ name }}</div>
        <div class="item_text">{{ detail | truncate }}</div>
        <ul class="col2 mb_10">
          <li class="item_shipping">送料込</li>
          <li class="item_price">{{ price | currency }}<span>税込</span></li>
        </ul>
        <div class="item_volume">在庫：{{ value }}</div>
      </li>
    </ul><!--.col2-->
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    id: Number,
    artist_id: Number,
    name: String,
    price: Number,
    value: Number,
    detail: String,
    item_photos: Array,
    authorized_at: String,
    created_at: String,
    updated_at: String,
  },
  data () {
    return {
    }
  },
  computed: {
    isSell () {
      return this.value > 0;
    },
    stock () {
      // 取り置き数も省いた商品在庫数
      return 1;
    }
  },
  filters: {
    formatDate (val) {
      return moment(val).format('YYYY/MM/DD');
    }
  },
  methods: {
  }
}
</script>
