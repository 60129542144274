<template>
  <div ref="payjpArea" class="payjp_card" :class="{'text-link': isTextLink}"></div>
</template>

<script>
  export default {
    name: 'PaymentCardChange',
    props: {
      title: String,
      onTokenCreatedSubscription: Function,
      onTokenFailedSubscription: Function,
      isTextLink: Boolean,
    },
    data () {
      return {
        scriptEl: null
      }
    },
    mounted() {
      // console.log('mounted');
      // HTML描画を待って要素をappendしたい
      setTimeout(() => {
        this.initialize();
      }, 500)

    },
    beforeDestroy () {
      // console.log('beforeDestroy');
      this.$el.removeChild(this.scriptEl);
      window.PayjpCheckout = null;  // pay.jpが２度目以降のiframe描画するようnullにする
      window.onTokenCreated = null;
      window.onTokenFailed = null;
    },
    methods: {
      initialize () {
        // console.log('initialize');
        this.appendPayJpScriptTag();
        window.onTokenCreated = this.onTokenCreated;
        window.onTokenFailed = this.onTokenFailed;
      },
      appendPayJpScriptTag() {
        const attrs = {
          'type': 'application/javascript',
          'data-key': process.env.VUE_APP_PAY_JP_PUBLIC_KEY,  //  'pk_test_086b54c0d4f5a304091ea034',
          'id': 'payjp-script',
          'src': 'https://checkout.pay.jp/',
          'class': 'payjp-button',
          'data-partial': true, // 入力後にtokenを作成し、windowを閉じる
          'data-text': (this.title) ? this.title : '支払い情報入力',
          // 'data-submit-text': '支払う',
          // 'data-previous-token': this.token,
          'data-lang': 'ja',
          'data-on-created': 'onTokenCreated',
          'data-on-failed': 'onTokenFailed',
          // 'data-name-placeholder': 'TEST TARO',
        };
        this.scriptEl = document.createElement('script');
        Object.keys(attrs).forEach(key => this.scriptEl.setAttribute(key, attrs[key]));
        this.$refs.payjpArea.appendChild(this.scriptEl);
        // console.log(this.$refs.payjpArea);
      },
      onTokenCreated () {
        // this.token = document.getElementsByName('payjp-token')[0].value;
        this.onTokenCreatedSubscription(document.getElementsByName('payjp-token')[0].value);  // props
      },
      onTokenFailed () {
        this.onTokenFailedSubscription(); // props
      }
    },
    watch: {
      $route() {
        setTimeout(() => {
          this.initialize();
        }, 500)
      },
    },
  };
</script>

<style>
  .payjp_card {
    text-align: right;
  }
  .payjp_card #payjp_checkout_box input[type=button]{
    cursor: pointer;
    display: inline-block;
    font-size: 14rem;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    /*background-color: #1ba79a;*/
    /*background-image: initial;*/
    padding: 15px 10px;
    border-radius: 30px;
    text-align: center;
    width: 200px;
    text-decoration: none;
    border: none;
    transition: all 0.3s ease 0s;
  }
  .payjp_card.text-link {
    text-align: initial;
  }
  .payjp_card.text-link #payjp_checkout_box input[type=button]{
    display: inline;
    color: initial;
    background: initial;
    padding: initial;
    margin: initial;
    text-decoration: underline;
    font-size: inherit;
    font-weight: initial;
    width: auto;
    border-radius: inherit;
    border: initial;
  }
</style>

