<template>
<transition name="modal">
<div class="modal-mask">
    <div class="modal-wrapper">
    <div class="modal-container">
        <div class="modal-header">
        <slot name="header"></slot>
        </div>

        <div class="modal-body">
        <slot></slot>
        </div>

        <div class="modal-footer">
        <button @click="$emit('close')">閉じる</button>
        </div>
    </div>
    </div>
</div>
</transition>
</template>

<script>
export default {
    // name: "Modal",
    name: 'ChatGptModal',
};
</script>

<style scoped>
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
}

.modal-wrapper {
display: table-cell;
vertical-align: middle;
}

.modal-container {
    width: 320px;
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
    margin-top: 0;
}

.modal-body {
    margin: 20px 0;
}

.modal-default-button {
    float: right;
}
</style>